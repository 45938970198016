import { compose, over, set, lensProp, lensPath, map } from 'ramda';
import { renameProp } from '../../../helpers/common';

import {
  REGISTER_FIELD_CHANGE,
  SET_TABLES,
  SET_RPS_INFO,
  SET_RPS_ERRORS,
  CLEAR_RPS_ERRORS,
  SET_SERVICES,
  SET_ECONOMIC_ACTIVIES,
  SET_CERTIFICATE_FILE,
  HANDLE_LOADING,
  HANDLE_CERTIFICATE_LOADING,
  HANDLE_RESTRICTIONS_LOADING,
  CLEAR_RPS_INFO,
  CLEAR_CERTIFICATE,
  SET_LOGO_FILE,
  SET_DATA_PAGE,
  SET_CITY_CODE,
  SET_IS_CITY_LOADED,
  SET_CONFIG_TYPE,
  SET_ESTABLISHMENTS_WITH_CONFIG,
  SET_SELECTED_ESTABLISHMENTS_WITH_CONFIG,
  SET_LAST_RPS_SUCCESS,
  IS_RPS_CONFIG,
  SET_IS_SEND_NFSE_LOADING,
  SET_BASIC_INFO,
  SET_AUTHENTICATION,
  SET_CERTIFICATE,
  SET_LOGIN,
  SET_TOKEN,
  SET_RPS_CONFIG,
  SET_RPS_FILTER,
  SET_IS_TERMS_OF_USE_LOADING,
} from './constants';

const defaultState = {
  dataPage: {},
  isTermsOfUseLoading: false,
  isLoading: false,
  isSendNfseLoading: false,
  isCertificateLoading: false,
  isRestrictionsLoading: false,
  availableCities: [],
  availableServices: [],
  availableEconomicActivities: [],
  establishmentsWithConfig: [],
  selectedEstablishmentsWithConfig: null,
  certificateFile: null,
  certificateFileFromAPI: null,
  logoFile: null,
  isCityLoaded: true,
  configType: 1,
  isRpsConfig: false,
  lastRpsSuccess: {
    series: null,
    number: null
  },
  submit: {
    documentId: null,
    municipalSubscription: null,
    stateSubscription: null,
    statusRps: 1,
    simpleNational: 2,
    encouraging: 2,
    issRetain: 2,
    automaticSendNfse: 0,
    districtTributationId: null,
    taxationRegime: null,
    issChargeability: null,
    cityCode: null,
    activityCode: null,
    discrimination: null,
    accessKey: null,
    certificate: null,
    autenticatorPassword: null,
    autenticatorPasswordConfirm: null,
    autenticatorToken: null,
    autenticatorUser: null,
    autenticatorUserPassword: null,
    password: null,
    passwordConfirm: null,
    taxSettingsOwner: 1,
    establishmentTaxSettingOwner: {},
    customSettings: {
      suportaEmissaoNFeSemCliente: {
        text: '',
        status: '0'
      },
      suportaEmissaoNFeClienteSemCpf: {
        text: '',
        status: '0'
      },
      suportaEmissaoNFeClienteSemEndereco: {
        text: '',
        status: '0'
      }
    },
    activityCodeList: {
      description: '',
      serviceCode: '',
      code: '',
      aliquot: ''
    },
    automaticGenerate: {
      serviceOrders: false,
      invoices: false,
      selectedTransactions: false,
      paymentMethods: []
    }
  },
  tables: {
    streetType: [],
    taxationRegime: [],
    taxationRegimeSpecial: []
  },
  custom_settings: {
    requiredServiceDescription: 0,
    requiredMunicipalServiceCode: 0,
    requiredCnae: 0,
    requiredServiceItem: 0,
    requiredSpecialTaxRegime: 0
  },
  basicInfo: {
    certificateId: '',
    logo: null,
    taxSettingsOwner: 1,
    establishmentTaxSettingOwner: {},
    uuid: '',
    fantasyName: '',
    companyName: '',
    documentId: '',
    phone: '',
    email: '',
    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
    cityCode: '',
    municipalSubscription: '',
    stateSubscription: '',
    encouraging: '',
    simpleNational: '',
    issRetain: '',
    issChargeability: '',
    description: '',
    serviceCode: '',
    code: '',
    aliquot: '',
    serviceItem: '',
    streetType: '',
    taxIncentive: '',
    taxationRegime: '',
    taxationRegimeSpecial: '',
    taxType: '',
    nfseNational: ''
  },
  basicInfoErrors: {
    companyName: null,
    fantasyName: null,
    documentId: null,
    email: null,
    phone: null,
    cityCode: null,
    city: null,
    district: null,
    street: null,
    state: null,
    postalCode: null,
    municipalSubscription: null,
    encouraging: null,
    simpleNational: null,
    streetType: null,
    taxIncentive: null,
    taxationRegime: null,
    aliquot: null,
    serviceCode: null
  },
  authentication: {
    certificate: false,
    login: false,
    password: false,
    token: false,
    sequence: false
  },
  certificate: {
    certificateFile: '',
    certificateName: '',
    certificateExpirationDate: '',
    certificatePassword: '',
    certificateId: ''
  },
  login: {
    autenticatorUser: '',
    autenticatorPassword: ''
  },
  token: {
    autenticatorToken: ''
  },
  rpsConfig: {
    rpsGenerateOnline: 1,
    rpsSeries: '',
    rpsNumber: ''
  },
  rpsFilter: {
    statusRps: 0,
    automaticSendNfse: 0,
    automaticGenerate: {
      invoices: 0,
      serviceOrders: 0,
      selectedTransactions: 0,
      paymentMethods: []
    }
  },
  restrictions: {
    requiredClient: 0,
    requiredClientAddress: 0,
    requiredClientDocumentId: 0,
    requiredClientToCancel: 0,
    requiredClientDocumentIdToCancel: 0,
    requiredClientName: 0,
    requiredCnae: '',
    requiredMunicipalServiceCode: '',
    requiredServiceItem: '',
    clientNameDefault: '',
    clientAddressDefault: '',
    clientDocumentIdDefault: ''
  },
  tooltips: {
    tooltipMunicipalServiceCode: '',
    tooltipMunicipalSubscription: '',
    tooltipServiceItem: ''
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      const field = action.field.split('.');
      let { value } = action;

      if(field[0] === 'statusRps') {
        value = parseInt(value, 10);
      }

      if(field.length > 1) {
        return set(lensPath(['submit', field[0], field[1]]), value, state);
      }

      return set(lensPath(['submit', field[0]]), value, state);
    }
    case SET_TABLES: {
      const {
        streetType,
        taxationRegime,
        taxationRegimeSpecial
      } = action.data;

      const formattedStreetType = streetType?.map(type => {
        return {
          value: type?.id,
          label: type?.name
        }
      });

      const formattedTaxationRegime = taxationRegime?.map(type => {
        return {
          value: type?.id,
          label: type?.name
        }
      });

      const formattedTaxationRegimeSpecial = taxationRegimeSpecial?.map(type => {
        return {
          value: type?.id,
          label: type?.name
        }
      });

      return {
        ...state,
        tables: {
          streetType: formattedStreetType,
          taxationRegime: formattedTaxationRegime,
          taxationRegimeSpecial: formattedTaxationRegimeSpecial
        }
      }
    }
    case SET_RPS_INFO: {
      const {
        taxSettingOwner,
        uuid,
        fantasyName,
        companyName,
        documentId,
        phone,
        email,
        postalCode,
        street,
        number,
        complement,
        district,
        city,
        state: uf,
        cityCode,
        municipalSubscription,
        stateSubscription,
        encouraging,
        simpleNational,
        issRetain,
        issChargeability,
        streetType,
        taxIncentive,
        taxationRegime,
        taxationRegimeSpecial,
        certificateFile,
        certificateName,
        certificateExpirationDate,
        certificatePassword,
        certificateId,
        nfse
      } = action.data;

      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          taxSettingsOwner: taxSettingOwner,
          uuid,
          certificateId,
          fantasyName,
          companyName,
          documentId,
          phone,
          email,
          postalCode,
          street,
          number,
          complement,
          district,
          city,
          state: uf,
          cityCode,
          municipalSubscription,
          stateSubscription,
          encouraging,
          simpleNational,
          issRetain,
          issChargeability,
          streetType,
          taxIncentive,
          taxationRegime,
          taxationRegimeSpecial,
          description: nfse?.description || '',
          aliquot: nfse?.aliquot || '',
          code: nfse?.code || '',
          serviceCode: nfse?.serviceCode || '',
          serviceItem: nfse?.taxCode || '',
          taxCode: nfse?.taxCode || '',
          taxType: nfse?.taxType || '',
          nfseNational: nfse?.nfseNational?.toString() || ''
        },
        certificate: {
          certificateFile,
          certificateName,
          certificateExpirationDate,
          certificatePassword,
          certificateId
        },
        login: {
          autenticatorUser: nfse?.login || '',
          autenticatorUserPassword: nfse?.password || ''
        },
        token: {
          autenticatorToken: nfse?.token || ''
        },
        certificateFileFromAPI: certificateFile
      }
    }
    case SET_RPS_ERRORS: {
      return {
        ...state,
        basicInfoErrors: {
          companyName: action.errors?.companyName ? action.errors?.companyName[0] : null,
          fantasyName: action.errors?.fantasyName ? action.errors?.fantasyName[0] : null,
          documentId: action.errors?.documentId ? action.errors?.documentId[0] : null,
          email: action.errors?.email ? action.errors?.email[0] : null,
          phone: action.errors?.phone ? action.errors?.phone[0] : null,
          cityCode: action.errors?.cityCode ? action.errors?.cityCode[0] : null,
          city: action.errors?.city ? action.errors?.city[0] : null,
          district: action.errors?.district ? action.errors?.district[0] : null,
          street: action.errors?.street ? action.errors?.street[0] : null,
          state: action.errors?.state ? action.errors?.state[0] : null,
          postalCode: action.errors?.postalCode ? action.errors?.postalCode[0] : null,
          municipalSubscription: action.errors?.municipalSubscription ? action.errors?.municipalSubscription[0] : null,
          encouraging: action.errors?.encouraging ? action.errors?.encouraging[0] : null,
          simpleNational: action.errors?.simpleNational ? action.errors?.simpleNational[0] : null,
          streetType: action.errors?.streetType ? action.errors?.streetType[0] : null,
          taxIncentive: action.errors?.taxIncentive ? action.errors?.taxIncentive[0] : null,
          taxationRegime: action.errors?.taxationRegime ? action.errors?.taxationRegime[0] : null,
          aliquot: action.errors['nfse.aliquot'] ? action?.errors['nfse.aliquot'][0] : null,
          serviceCode: action.errors['nfse.serviceCode'] ? action?.errors['nfse.serviceCode'][0] : null
        }
      }
    }
    case CLEAR_RPS_ERRORS: {
      return {
        ...state,
        basicInfoErrors: defaultState.basicInfoErrors
      }
    }
    case SET_SERVICES: {
      const formatServices = data =>
        data &&
        map(
          compose(
            renameProp('code', 'value'),
            renameProp('description', 'label')
          ),
          data
        );
      const services = compose(
        set(lensProp('isLoading'), false),
        set(lensProp('availableServices'), formatServices(action?.data))
      );

      return services(state);
    }
    case SET_ECONOMIC_ACTIVIES: {
      const formatActivitiesOptions = options => {
        const { code } = options;
        const convertOption = compose(
          over(lensProp('label'), description => `${code} - ${description}`),
          renameProp('description', 'label'),
          renameProp('code', 'value')
        );

        return convertOption(options);
      }
      const formatActivities = data =>
        data && map(formatActivitiesOptions, data);

      const economicActivities = compose(
        set(lensProp('isLoading'), false),
        set(
          lensProp('availableEconomicActivities'),
          formatActivities(action?.data)
        )
      );

      return economicActivities(state);
    }
    case SET_CERTIFICATE_FILE: {
      return set(lensProp('certificateFile'), action?.file, state);
    }
    case SET_LOGO_FILE: {
      return set(lensProp('logoFile'), action?.file, state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action?.status, state);
    }
    case HANDLE_CERTIFICATE_LOADING: {
      return set(lensProp('isCertificateLoading'), action?.status, state);
    }
    case HANDLE_RESTRICTIONS_LOADING: {
      return set(lensProp('isRestrictionsLoading'), action?.status, state);
    }
    case CLEAR_RPS_INFO: {
      const clear = compose(
        set(lensProp('availableCities'), state.availableCities),
        set(lensProp('availableServices'), state.availableServices),
        set(lensProp('availableEconomicActivities'), state.availableEconomicActivities),
        set(lensProp('establishmentsWithConfig'), state.establishmentsWithConfig)
      );

      return clear(defaultState);
    }
    case CLEAR_CERTIFICATE: {
      const clear = compose(
        set(lensPath(['submit', 'password']), ''),
        set(lensPath(['submit', 'passwordConfirm']), ''),
        set(lensPath(['submit', 'certificate']), null),
        set(lensProp('certificateFile'), null),
        set(lensProp('isCertificateLoading'), false)
      );

      return clear(state);
    }
    case SET_CITY_CODE: {
      const newCityCode = compose(set(lensPath(['submit', 'cityCode']), action?.code));
      return newCityCode(state);
    }
    case SET_DATA_PAGE: {
      return {
        ...state,
        dataPage: action?.data,
      }
    }
    case SET_IS_CITY_LOADED: {
      return {
        ...state,
        isCityLoaded: action?.isCityLoaded
      }
    }
    case SET_CONFIG_TYPE: {
      return {
        ...state,
        configType: action?.config
      }
    }
    case SET_ESTABLISHMENTS_WITH_CONFIG: {
      return {
        ...state,
        establishmentsWithConfig: action?.establishments
      }
    }
    case SET_SELECTED_ESTABLISHMENTS_WITH_CONFIG: {
      return {
        ...state,
        selectedEstablishmentsWithConfig: action?.id
      }
    }
    case SET_LAST_RPS_SUCCESS: {
      return {
        ...state,
        lastRpsSuccess: action?.rps
      }
    }
    case IS_RPS_CONFIG: {
      return {
        ...state,
        isRpsConfig: action?.isConfig
      }
    }
    case SET_IS_SEND_NFSE_LOADING: {
      return {
        ...state,
        isSendNfseLoading: action?.isLoading
      }
    }
    case SET_BASIC_INFO: {
      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          ...action.data
        }
      }
    }
    case SET_AUTHENTICATION: {
      const {
        certificate,
        login,
        password,
        token,
        sequence,
        custom_settings: {
          requiredServiceDescription,
          requiredMunicipalServiceCode,
          requiredCnae,
          requiredServiceItem,
          requiredSpecialTaxRegime,
          tooltipMunicipalServiceCode,
          tooltipMunicipalSubscription,
          tooltipServiceItem,
          requiredClient,
          requiredClientAddress,
          requiredClientDocumentId,
          requiredClientToCancel,
          requiredClientDocumentIdToCancel,
          requiredClientName,
          clientNameDefault,
          clientAddressDefault,
          clientDocumentIdDefault
        }
      } = action.data;

      return {
        ...state,
        authentication: {
          ...state.authentication,
          certificate,
          login,
          password,
          token,
          sequence
        },
        custom_settings: {
          ...state.custom_settings,
          requiredServiceDescription,
          requiredMunicipalServiceCode,
          requiredCnae,
          requiredServiceItem,
          requiredSpecialTaxRegime
        },
        restrictions: {
          ...state.restrictions,
          requiredClient: requiredClient || 0,
          requiredClientAddress: requiredClientAddress || 0,
          requiredClientDocumentId: requiredClientDocumentId || 0,
          requiredClientToCancel: requiredClientToCancel || 0,
          requiredClientDocumentIdToCancel: requiredClientDocumentIdToCancel || 0,
          requiredClientName: requiredClientName || 0,
          requiredCnae: requiredCnae || 0,
          requiredMunicipalServiceCode: requiredMunicipalServiceCode || 0,
          requiredServiceItem: requiredServiceItem || 0,
          clientNameDefault: clientNameDefault || '',
          clientAddressDefault: clientAddressDefault || '',
          clientDocumentIdDefault: clientDocumentIdDefault || ''
        },
        tooltips: {
          ...state.tooltips,
          tooltipMunicipalServiceCode,
          tooltipMunicipalSubscription,
          tooltipServiceItem
        }
      }
    }
    case SET_CERTIFICATE: {
      return {
        ...state,
        certificate: action.data
      }
    }
    case SET_LOGIN: {
      return {
        ...state,
        login: action.data
      }
    }
    case SET_TOKEN: {
      return {
        ...state,
        token: action.data
      }
    }
    case SET_RPS_CONFIG: {
      return {
        ...state,
        rpsConfig: action.data
      }
    }
    case SET_RPS_FILTER: {
      const {
        statusRps,
        automaticSendNfse,
        automaticGenerateNfse
      } = action?.data;

      const getAutomaticGenerate = () => {
        if(automaticGenerateNfse) {
          const {
            invoices,
            serviceOrders,
            selectedTransactions,
            paymentMethods
          } = JSON.parse(automaticGenerateNfse);

          return {
            invoices,
            serviceOrders,
            selectedTransactions,
            paymentMethods
          }
        }

        return {}
      }

      return {
        ...state,
        rpsFilter: {
          statusRps,
          automaticSendNfse,
          automaticGenerate: getAutomaticGenerate()
        }
      }
    }
    case SET_IS_TERMS_OF_USE_LOADING: {
      return {
        ...state,
        isTermsOfUseLoading: action.isLoading
      }
    }
    default: {
      return state;
    }
  }
}