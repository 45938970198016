import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Grid, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Card from 'components/Card';
import Loader from 'components/Loader';
import CardFooter from 'components/CardFooter';
import Select from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from '../../components/Title';
import Subtitle from '../../components/Subtitle';

import { setImagePath, setImageUrl } from '../../store/actions';
import { loadConfigurations, upsertConfigurations, uploadFile } from '../../store/thunk';

import { availablePrints } from '../resources';

export default function Receipt() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.operational);
  const { settingHistoryUserName, settingHistoryDateTime } = useSelector(state => state.operational);
  const { urlLogo, pathLogo, print, printNote } = useSelector(state => state.operational.receipt);

  const [receiptPrint, setReceiptPrint] = useState(print || null);
  const [receiptPrintNote, setReceiptPrintNote] = useState(printNote || null);

  const [printNoteLength, setPrintNoteLength] = useState(receiptPrintNote?.length || 0);
  const printNoteMaxLength = 255;

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadConfigurations(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    setReceiptPrint(print);
    setReceiptPrintNote(printNote);
  }, [print, printNote]);

  const handleUploadLogo = e => {
    const file = e?.target?.files[0];
    const { type, size } = e?.target?.files[0];

    const supportTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    const maxSize = 5242880;

    if(!supportTypes.includes(type)) {
      return toast.error('O formato da imagem não é suportado.');
    }

    if(size > maxSize) {
      return toast.error('O tamanho máximo da imagem não pode exceder 5 MB.');
    }

    dispatch(uploadFile(userId, establishmentId, { file }));
  }

  const handleConfigurationsSave = () => {
    const params = {
      pathLogo,
      print: receiptPrint,
      printNote: receiptPrintNote,
      page: 'Comprovantes'
    }

    dispatch(upsertConfigurations(userId, establishmentId, params));
  }

  return(
    <Card title="Comprovantes">
      <Grid xs={12} alignItems='center'>
        <Loader isLoading={isLoading}>
          <Grid xs={12}>
            <Grid container>
              {(!!settingHistoryUserName && !!settingHistoryDateTime) && (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography>
                    <b>Última alteração feita por {settingHistoryUserName} em {moment(settingHistoryDateTime).format('LLL')}</b>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Title>Faça o upload do seu <strong>logotipo</strong></Title>
                <Subtitle>Imagem utilizada em recibos por email, PDF e orçamentos. Apenas nos formatos: PNG, JPEG e JPG.</Subtitle>
                <input
                  name="pathLogo"
                  className="mt-2"
                  type="file"
                  onChange={handleUploadLogo}
                />
              </Grid>
            </Grid>
            {!!urlLogo && (
              <Grid container>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 10 }}>
                  <img src={urlLogo} width="72" alt="Logo" />
                  <IconButton
                    onClick={() => {
                      dispatch(setImagePath(''));
                      dispatch(setImageUrl(''));
                    }}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Title>
                <strong>Emissão de ticket</strong>
              </Title>
              <Subtitle>Escolha como deseja emitir os tickets na entrada e saída dos veículos</Subtitle>
              <Select
                name="print"
                label="Selecione a emissão"
                options={availablePrints}
                value={receiptPrint || ''}
                onChange={e => setReceiptPrint(e.target.value)}
              />
              <Subtitle>
                Escreva uma mensagem de observação fixa para as emissões{' '}
                <strong>(Opcional)</strong>
              </Subtitle>
              <Input
                name="printNote"
                label="Observação na emissão"
                placeholder="Escreva uma mensagem de observação fixa para as emissões (Opcional. Máx: 255 caracteres)"
                style={{ marginLeft: 0 }}
                maxLength={printNoteMaxLength}
                value={receiptPrintNote || ''}
                onChange={e => {
                  setReceiptPrintNote(e.target.value);
                  setPrintNoteLength(e.target.value.length);
                }}
              />
              <Subtitle>Caracteres: {printNoteLength}/{printNoteMaxLength}</Subtitle>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <CardFooter>
              <Button
                color="primary"
                onClick={handleConfigurationsSave}
              >
                Salvar
              </Button>
            </CardFooter>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}