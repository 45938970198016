import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export default function RestrictionsView() {
  const styles = useStyles();

  const { restrictions } = useSelector(state => state.provisionalReceiptConfig);

  return(
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 20, borderBottom: '1px solid #E4E7EA' }}>
        <Typography color="primary">
          <b>Restrições de envio de NFS-e de cada prefeitura</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>Cliente/Tomador:</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          Obrigatoriedade no nome:{' '}
          <b>{restrictions?.requiredClientName ? 'Sim' : 'Não'}</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          Obrigatoriedade no CPF/CNPJ:{' '}
          <b>{restrictions?.requiredClientDocumentId ? 'Sim' : 'Não'}</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          Obrigatoriedade no endereço:{' '}
          <b>{restrictions?.requiredClientAddress ? 'Sim' : 'Não'}</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          Obrigatoriedade no cadastro:{' '}
          <b>{restrictions?.requiredClient ? 'Sim' : 'Não'}</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>Empresa:</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          CNAE:{' '}
          <b>{restrictions?.requiredCnae ? 'Sim' : 'Não'}</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          Código Serviço:{' '}
          <b>{restrictions?.requiredMunicipalServiceCode ? 'Sim' : 'Não'}</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          Código Item Lista Serviço:{' '}
          <b>{restrictions?.requiredServiceItem ? 'Sim' : 'Não'}</b>
        </Typography>
        <br />
        <Typography variant="h6" color="primary" className={styles.text}>
          <b>Cancelamento de NFS-e</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          NF-e precisa do cadastro do cliente:{' '}
          <b>{restrictions?.requiredClientToCancel ? 'Sim' : 'Não'}</b>
        </Typography>
        <Typography variant="h6" color="primary" className={styles.text}>
          NF-e precisa do CPF/CNPJ do cliente:{' '}
          <b>{restrictions?.requiredClientDocumentIdToCancel ? 'Sim' : 'Não'}</b>
        </Typography>
      </Grid>
    </Grid>
  );
}